.header {
  background: var(--bg-green-1);
  display: flex;
  height: 60px;
  width: 100%;
}

.navbar {
}

.sectionList {
  display: flex;
  list-style: none;
  padding: 0;
  gap: 5px;
  height: 100%;
}

.burgerMenuSectionList {
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  padding: 10px;
  border-radius: 5px;
  z-index: 999;
}

.section {
  transition: filter 0.3s ease-in-out;
  background: var(--bg-green-1);
}

.section:hover {
  background: var(--bg-green-dark-1);
}

.section:active,
.sectionActive {
  background: var(--bg-green-dark-2);
}

.sectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: var(--text-white-1);
  padding: 5px;
}

.sectionName {
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
  font-size: 12px;
}

.burgerMenu {
  display: none;
  cursor: pointer;
}

.burgerMenu svg {
  width: 30px;
  height: 30px;
}

@media (max-width: 768px) {
  .header {
    height: 40px;
  }

  .burgerMenu {
    display: block;
    position: relative;
    z-index: 999;
    margin: auto;
  }

  .sectionList {
    display: none;
  }

  .burgerMenuSectionList.open {
    display: block;
  }
}

