:root {
  --bg-green-1: #3cb371;
  --bg-green-2: #54b37f;
  --bg-green-dark-1: #38a569;
  --bg-green-dark-2: rgb(62, 153, 103);
  --bg-blue-1: #1a67a6;
  --bg-blue-light-1: #258ee5;
  --bg-gray-light-1: #cfcfcf;
  --bg-gray-light-2: #dbdbdb;
  --yellow-1: #fff457;
  --bg-red-1: rgb(243, 47, 47);
  --bg-red-light-1: #ffcccc;
  --text-white-1: rgb(236, 236, 236);
}

.App {
  margin: 0 auto;
}

.view {
  padding: 1%;
}

.active {
}

.hide {
  display: none;
}

.transparent {
  opacity: 0;
}

.linkToModel {
  color: var(--bg-green-1);
}

.linkToModel:hover {
  cursor: pointer;
}

.App .MuiInputBase-root {
  font-size: inherit;
}

.modalWindowDefaultContainer {
  display: flex;
  flex-direction: column;
  width: 30vw;
  padding: 10px;
  background-color: var(--bg-gray-light-1);
  border-radius: 10px;
  gap: 10px;
  overflow-x: hidden;
}

.modalWindowDefaultFieldInput {
  display: flex;
  gap: 10px;
}

.fieldInput {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--bg-blue-1);
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .modalWindowDefaultContainer {
    width: 50vw;
  }
}
@media (max-width: 992px) {
  .modalWindowDefaultContainer {
    width: 60vw;
  }
}
@media (max-width: 768px) {
  .modalWindowDefaultContainer {
    width: 70vw;
  }
}
@media (max-width: 480px) {
  .modalWindowDefaultContainer {
    width: 90vw;
  }
}

@media (max-width: 320px) {
}

.blockDownload {
  padding: 10px;
  background-color: #cfcfcf;
  border-radius: 10px;
  font-size: 18px;
}

/* Определение горизонтального отображения */
.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center; /* выравнивание по вертикали */
  justify-content: center; /* выравнивание по горизонтали */
}

/* Определение вертикального отображения */
.vertical {
  display: flex;
  flex-direction: column;
  align-items: center; /* выравнивание по горизонтали */
  justify-content: center; /* выравнивание по вертикали */
  width: 100%;
}

/* Дополнительные классы для гибкости */
.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}
