.container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  background-color: var(--bg-gray-light-1);
  z-index: 10;
}

