.authButtons {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.authButton {
  margin: auto 5px;
  padding: 5px 10px;
  background-color: var(--bg-blue-1);
  color: var(--text-white-1);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
