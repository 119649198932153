.viewData {
  max-width: 35%;
}

@media (max-width: 916px) {
  .viewData {
    max-width: 100%;
  }
}

.coordinationeValuationBtns {
  display: flex;
}

.coordinationeValuationBtns button {
  background-color: var(--bg-gray-light-1);
}

.coordinationeValuationBtns button:hover {
  background-color: var(--bg-blue-1);
}

.coordinationeValuationBtns .active {
  background-color: var(--bg-blue-1);
}

.blockSelectStorehouse {
  min-width: 150px;
}

.productAddInformationContainer {
  width: 30vw;
}

@media (max-width: 1200px) {
}
@media (max-width: 992px) {
  .productAddInformationContainer {
    width: 60vw;
  }
}
@media (max-width: 768px) {
  .productAddInformationContainer {
    width: 75vw;
  }
}
@media (max-width: 480px) {
  .productAddInformationContainer {
    width: 90vw;
  }
}
@media (max-width: 320px) {
  .productAddInformationContainer {
    width: 90vw;
  }
}
