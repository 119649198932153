.dropDownMediaBlock {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
}

.mediaContent {
  position: relative;
  height: 100%;
  text-align: center;
}

.mediaContent img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modelMedia {
  max-width: 90vw;
}
