.listAttr { 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
}

.addBtn {
}

.attr {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.selectedFilesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); /* Автоматическая подгонка столбцов */
  gap: 15px; /* Отступы между файлами */
  margin-top: 10px;
  width: 100%; 
}

.fileItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Пространство между элементами */
  width: 80px; /* Уменьшенная ширина */
  height: 120px; /* Увеличена высота для размещения кнопки */
  overflow: hidden; /* Скрытие излишков изображения */
  border: 1px solid #ccc; /* Легкая рамка для визуального разделения */
  border-radius: 4px; /* Скругленные углы для более аккуратного вида */
  padding: 5px; /* Добавлен внутренний отступ */
}

.fileItem img {
  width: 100%; /* Подгонка изображения по ширине */
  height: 80px; /* Высота изображения */
  object-fit: cover; /* Обрезка изображения для сохранения пропорций */
}

.fileItem button {
  font-size: 12px; /* Размер шрифта кнопки */
  padding: 3px 8px; /* Уменьшенные отступы кнопки */
  margin-top: 5px; /* Отступ сверху для кнопки */
  width: 100%; /* Кнопка по всей ширине блока */
}
