.filter {
  width: 16.6%;
  padding: 6px 7px;
  font-size: inherit;
}

.filter p {
  margin-bottom: 3px;
}

.filter input[type='text'] {
  width: 100%;
}

.filter select {
  width: 100%;
}

@media (max-width: 1200px) {
  .filter {
    width: 20%;
  }
}
@media (max-width: 992px) {
  .filter {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .filter {
    width: 33%;
  }
}
@media (max-width: 480px) {
  .filter {
    width: 50%;
  }
}
@media (max-width: 320px) {
}
