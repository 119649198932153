.viewData {
  max-width: 42%;
}

.deliveryCheckBlock {
}

.deliveryCheckBlocks {
  display: flex;
  gap: 10px;
}


.deliveryCheckBlocksColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
