.modelFilters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 85%;
  margin: 10px 0;
  padding: 15px;
  border-radius: 20px;
  background-color: var(--bg-gray-light-1);
}

.controlBtns {
  display: flex;
  justify-content: center;
  width: 16.6%;
  padding: 6px 7px;
}

@media (max-width: 1200px) {
  .modelFilters {
    min-width: 100%;
  }

  .controlBtns {
    width: 20%;
  }
}
@media (max-width: 992px) {
  .controlBtns {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .controlBtns {
    width: 33%;
  }
}
@media (max-width: 480px) {
  .controlBtns {
    width: 50%;
  }
}
@media (max-width: 320px) {
}
