.blockAttr {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 8px 0;
  font-size: 12px;
}

.blockAttr p {
  min-width: 38%;
  padding-right: 10px;
}

.blockAttr input,
.blockAttr select {
  font-size: inherit;
  padding: 4px;
  width: 100%;
}

.sectionTitle {
  margin: 20px 0 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  color: #333;
}

.shipmentSection {
  margin: 15px 0;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.shipmentTitle {
  margin: 0 0 10px;
  font-size: 13px;
  color: #666;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 5px;
}