.listAttr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;

}

.addBtn {
}

.attr {
  display: flex;
  width: 100%;
}
