.modelsControls {
    display: flex;
    flex-wrap: wrap;
    padding: auto 0;
    gap: 10px;
}

.btn {
    display: flex;
    margin: auto 0;
    height: 80%;
    cursor: pointer;
}

.btnActivated {
    background-color: var(--yellow-1);
    color: black
}

.btnIcon{
    margin: auto 1px;
    color: var(--text-white-1);
    background: var(--bg-green-1);
    border-radius: 50%;
}
