.viewData {
    max-width: 35%;
}

.inputProducts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: max-content;
}

.mоdalCreateProduct {
    background-color: white;
    padding: 20px;
    min-width: 90vw;
    height: 90vh;
    border-radius: 10px;
}