.AddInformationContainer {
  width: 40vw;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--bg-gray-light-1);
  border-radius: 10px;
  gap: 10px;
}

.AddInformationContainer .controlBtns {
  display: flex;
}

.AddInformationContainer .fieldInput p {
  min-width: 30%;
}
.AddInformationContainer .fieldInput input {
  width: 100%;
}


@media (max-width: 1200px) {
  .AddInformationContainer {
    width: 44vw;
  }
}
@media (max-width: 992px) {
  .AddInformationContainer {
    width: 54vw;
  }
}
@media (max-width: 768px) {
  .AddInformationContainer {
    width: 60vw;
  }
}
@media (max-width: 480px) {
  .AddInformationContainer {
    width: 80vw;
  }
}
@media (max-width: 320px) {
  .AddInformationContainer {
    width: 90vw;
  }
}
