.inputData {
  max-width: 60%;
}

@media (max-width: 1200px) {
  .inputData {
    max-width: 50vw;
  }
}
@media (max-width: 992px) {
  .inputData {
    max-width: 60vw;
  }
}
@media (max-width: 768px) {
  .inputData {
    max-width: 70vw;
  }
}
@media (max-width: 480px) {
  .inputData {
    max-width: 100vw;
  }
}

@media (max-width: 320px) {
}
