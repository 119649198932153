.modalContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--bg-gray-light-1);
  border-radius: 10px;
  gap: 10px;
}

.createUnidentifiedDeliveryConfirmContainer {
  position: absolute;
  left: 0;
  width: 100%;
  padding-top: 30px;
}


.mоdalCreateUnidentifiedDelivery {
  background-color: white;
  padding: 20px;
  min-width: 90vw;
  height: 90vh;
  border-radius: 10px;
}