.productWithQtyContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.productWithQtyContainer .qtyInput {
    width: 60px;
}

.attrsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;

}

.attr {
  display: flex;
  width: 100%;
}
