.barcodeVideo {
  width: 30vw;
  height: 100%;
  border-radius: 10px;
}

@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
  .barcodeVideo {
    width: 90vw;
  }
}
@media (max-width: 480px) {
}
@media (max-width: 320px) {
}
