.pageSwitch {
  display: flex;
  margin: 10px;
  gap: 5px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.btnIcon {
  padding: 0;
  font-size: 10px;
  margin: 3px;
  /* color: black;
  background-color: white; */
}

.pageNumber {
  margin: auto 10px;
  font-size: 14px;
}
