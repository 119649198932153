.pageCreateModel {
  margin: 5px;
  position: relative;
}

.inputData {
  margin-top: 40px;
  width: max-content;
}

.pageCreateModel .inputName {
  margin-bottom: 10px;
}

.pageCreateModel .inputName p {
  margin-bottom: 5px;
}

.pageViewModel {
  margin: 5px;
  position: relative;
}

.pageViewModel .controls {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.pageViewModel .controls button {
  margin: 4px 4px;
}

.pageViewModel .viewData {
  max-width: max-content;
}

.pageViewModel .viewName {
  margin-bottom: 10px;
}

.pageViewModel .listModels {
  max-width: max-content;
}

.listModels .modelBlock {
  width: 100%;
  margin: 2px;
  padding: 2px;
  border: 2px solid var(--bg-green-1);
  border-radius: 4px;
}

.pageViewModel .listDropDomnMedia {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.pageViewModel .horizontallyContainer {
  display: flex;
  gap: 20px;
}

.pageUpdateModel {
  margin: 5px;
  position: relative;
}

.pageUpdateModel .inputData {
  margin-top: 40px;
  min-width: max-content;
  width: 30%;
}

.pageUpdateModel .inputName {
  margin-bottom: 10px;
}

.pageUpdateModel p {
  margin-bottom: 5px;
}

.pageUpdateModel .listDropDomnMedia {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.openingBlockAttr {
  display: flex;
  align-items: start;
  margin: 8px 0;
}

.openingBlockAttrName {
  padding: 5px;
  padding-right: 10px;
  min-width: 150px;
  max-width: 50px;
  width: 100%;
  word-wrap: break-word
}

.openingBlockAttrAttrInput {
  /* flex: 0 0 60%; */
}

.openingBlockAttrValue {
}

.openingBlockAttr input,
.openingBlockAttr select,
.openingBlockAttr span {
  font-size: inherit;
  padding: 5px;
  flex: 1;
}

.openingBlockAttr input[type="checkbox"] {
  flex: 0;
}

.openingBlockAttr label {
  font-size: inherit;
}

.openingBlockAttr .MuiAutocomplete-root {
  width: 100%;
}

.openingBlockErrorAttr {
  color: var(--bg-red-1);
  font-size: 12px;
}

.DropDownMediaBlockDelete {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.relatedModelComtrols {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}
