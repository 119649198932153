/* .formFields {
  text-align: right;
  font-size: 19px;

}

.formField {
  margin: 5px;
}

.formField label {
  margin-right: 5px;
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  margin-block: 5px;
  text-align: center;
  border-radius: 8px;
  background-color: var(--bg-green-1);
  color: var(--text-white-1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.form button {
  padding: 5px 10px;
  background-color: var(--bg-blue-1);
  color: var(--text-white-1);
  border: none;
  border-radius: 5px;
} */

.formFields {
  font-size: 19px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Выравнивание полей по левому краю */
}

.formField {
  margin: 5px 0;
  display: flex;
  justify-content: space-between; /* Пространство между label и input */
  width: 100%;
}

.formField label {
  margin-right: 5px;
  text-align: right; /* Выравнивание текста label по правому краю */
  width: 150px; /* Фиксированная ширина для label */
}

.formField input,
.formField select {
  flex: 1; /* Поля ввода занимают оставшееся пространство */
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px; /* Увеличил padding для большего пространства внутри формы */
  margin-block: 5px;
  text-align: center;
  border-radius: 8px;
  background-color: var(--bg-green-1);
  color: var(--text-white-1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.form button {
  padding: 5px 10px;
  background-color: var(--bg-blue-1);
  color: var(--text-white-1);
  border: none;
  border-radius: 5px;
  margin-top: 10px; /* Отступ сверху для кнопки */
}






