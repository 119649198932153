.GlobalMsg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 10px;
  box-shadow: 0 3px 25px -5px rgba(0, 0, 0, 0.5);
  background-color: var(--bg-green-2);
  color: var(--text-white-1);
  z-index: 3;
}