.selectStorageCellContainer {
  width: 20vw;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--bg-gray-light-1);
  border-radius: 10px;
  gap: 10px;
  overflow-x: hidden;
}

@media (max-width: 1200px) {
  .selectStorageCellContainer {
    width: 30vw;
  }
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
  .selectStorageCellContainer {
    width: 40vw;
  }
}
@media (max-width: 480px) {
  .selectStorageCellContainer{
    width: 90vw;
  }
}
@media (max-width: 320px) {
}

