.confirmDialog {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 10px;
  width: 12%;
  border-radius: 6px;
  box-shadow: 0 3px 25px -10px rgba(0, 0, 0, 0.5);
  background-color: var(--bg-gray-light-2);
  z-index: 1;
}

.message {
    margin-bottom: 10px;
}

.confirmDialog button {
    font-size: 14px;
}