.modelViewBox {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 10px;
}

.modelViewBox table {
  border-collapse: collapse;
}

.modelViewBox th {
  border-bottom: 2px solid var(--bg-green-1);
  color: var(--bg-green-1);
}

.modelViewBox tr {
  border-bottom: 1px solid var(--bg-gray-light-1);
}

.modelViewBox tbody tr:hover {
  background: var(--bg-gray-light-1);
}

.modelViewBox tr:last-child {
  border-bottom: 1px solid var(--bg-green-1);
}

.modelViewBox td,
.modelViewBox th {
  padding: 3px 5px;
}

.settings {
}

.settingsBtn {
  font-size: inherit;
}

.settingsMenu {
  position: absolute;
  /* left: 50%; */
  transform: translateX(-50%);
  margin-top: 6px;
  padding: 10px;
  border-radius: 5px;
  border-color: var(--bg-gray-light-1);
  box-shadow: 0 0 20px -4px rgba(0, 0, 0);
  background-color: white;
}
