.ViewDataBlocks {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.viewLogisticsData {
  margin-top: 40px;
}

.AddLogisticsData {
  width: 40vw;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--bg-gray-light-1);
  border-radius: 10px;
  gap: 10px;
}

.AddLogisticsData .controlBtns {
  display: flex;
}

.childModels {
  margin-bottom: 40px;
}
