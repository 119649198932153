.modal_window {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 0.5s;
  opacity: 0;
  z-index: 2;
  overflow: auto;
}

.modal_window.active {
  opacity: 1;
  pointer-events: all;
}

.modal_window__content {
  width: max-content;
  max-height: 90vh;
  overflow-y: auto;
}
