.openingBlock {
  background: var(--bg-gray-light-2);
  padding: 10px;
  height: min-content;
  width: 100%;
}

.openPart {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: none;
  color: black;
}

.hidePart {
  margin-top: 20px;
  padding-right: 20px;
}

.title {
}

.openingBlock input {width: 100%;}