.storehouseEditShelvingsAndStorageCells{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 30px;
}

.pageViewModel {
    min-width: 10%;
    margin: 5px;
}

.storehouseEditShelvingsAndStorageCells .manageForm{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px;
}

.mоdalCreateShelving {
    background-color: white;
    padding: 20px;
    min-width: 90vw;
    height: 90vh;
    border-radius: 10px;
}


.mоdalCreateStorageCell {
    background-color: white;
    padding: 20px;
    min-width: 90vw;
    height: 90vh;
    border-radius: 10px;
}

@media (max-width: 1125px) {
 .storehouseEditShelvingsAndStorageCells {
    display: block;
 }
}