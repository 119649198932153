

.inputData {
  max-width: 40%;
}


.inputName input[type='text'] {
  width: 100%;
}

