.viewData {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  padding-bottom: 10px;
}


.submitClosingDocumentsContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--bg-gray-light-1);
  border-radius: 10px;
  gap: 10px;
}

.submitClosingDocumentsContainer .controlBtns {
  display: flex;
}

.submitClosingDocumentsContainer .fieldInput {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1.5fr;
}

.submitClosingDocumentsContainer .fieldInputName {
  padding: 5px;
  padding-right: 10px;
}

.documentBlock {
  background-color: var(--bg-gray-light-1);
  border-radius: 10px;
  padding: 10px;
}

.documentBlock a {
  font-size: 26px;
}